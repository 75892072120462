import createLucideIcon from '../createLucideIcon';

/**
 * @component @name IceCream2
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTIgMTdjNSAwIDgtMi42OSA4LTZINGMwIDMuMzEgMyA2IDggNlptLTQgNGg4bS00LTN2M001LjE0IDExYTMuNSAzLjUgMCAxIDEgNi43MSAwIiAvPgogIDxwYXRoIGQ9Ik0xMi4xNCAxMWEzLjUgMy41IDAgMSAxIDYuNzEgMCIgLz4KICA8cGF0aCBkPSJNMTUuNSA2LjVhMy41IDMuNSAwIDEgMC03IDAiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/ice-cream-2
 * @see https://lucide.dev/guide/packages/lucide-react - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {JSX.Element} JSX Element
 *
 */
const IceCream2 = createLucideIcon('IceCream2', [
  [
    'path',
    {
      d: 'M12 17c5 0 8-2.69 8-6H4c0 3.31 3 6 8 6Zm-4 4h8m-4-3v3M5.14 11a3.5 3.5 0 1 1 6.71 0',
      key: 'g86ewz',
    },
  ],
  ['path', { d: 'M12.14 11a3.5 3.5 0 1 1 6.71 0', key: '4k3m1s' }],
  ['path', { d: 'M15.5 6.5a3.5 3.5 0 1 0-7 0', key: 'zmuahr' }],
]);

export default IceCream2;
