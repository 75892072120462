import createLucideIcon from '../createLucideIcon';

/**
 * @component @name Mic2
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJtMTIgOC05LjA0IDkuMDZhMi44MiAyLjgyIDAgMSAwIDMuOTggMy45OEwxNiAxMiIgLz4KICA8Y2lyY2xlIGN4PSIxNyIgY3k9IjciIHI9IjUiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/mic-2
 * @see https://lucide.dev/guide/packages/lucide-react - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {JSX.Element} JSX Element
 *
 */
const Mic2 = createLucideIcon('Mic2', [
  ['path', { d: 'm12 8-9.04 9.06a2.82 2.82 0 1 0 3.98 3.98L16 12', key: 'zoua8r' }],
  ['circle', { cx: '17', cy: '7', r: '5', key: '1fomce' }],
]);

export default Mic2;
