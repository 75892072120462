import createLucideIcon from '../createLucideIcon';

/**
 * @component @name CalendarHeart
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMjEgMTBWNmEyIDIgMCAwIDAtMi0ySDVhMiAyIDAgMCAwLTIgMnYxNGMwIDEuMS45IDIgMiAyaDciIC8+CiAgPHBhdGggZD0iTTE2IDJ2NCIgLz4KICA8cGF0aCBkPSJNOCAydjQiIC8+CiAgPHBhdGggZD0iTTMgMTBoMTgiIC8+CiAgPHBhdGggZD0iTTIxLjI5IDE0LjdhMi40MyAyLjQzIDAgMCAwLTIuNjUtLjUyYy0uMy4xMi0uNTcuMy0uOC41M2wtLjM0LjM0LS4zNS0uMzRhMi40MyAyLjQzIDAgMCAwLTIuNjUtLjUzYy0uMy4xMi0uNTYuMy0uNzkuNTMtLjk1Ljk0LTEgMi41My4yIDMuNzRMMTcuNSAyMmwzLjYtMy41NWMxLjItMS4yMSAxLjE0LTIuOC4xOS0zLjc0WiIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/calendar-heart
 * @see https://lucide.dev/guide/packages/lucide-react - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {JSX.Element} JSX Element
 *
 */
const CalendarHeart = createLucideIcon('CalendarHeart', [
  ['path', { d: 'M21 10V6a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v14c0 1.1.9 2 2 2h7', key: '1sfrvf' }],
  ['path', { d: 'M16 2v4', key: '4m81vk' }],
  ['path', { d: 'M8 2v4', key: '1cmpym' }],
  ['path', { d: 'M3 10h18', key: '8toen8' }],
  [
    'path',
    {
      d: 'M21.29 14.7a2.43 2.43 0 0 0-2.65-.52c-.3.12-.57.3-.8.53l-.34.34-.35-.34a2.43 2.43 0 0 0-2.65-.53c-.3.12-.56.3-.79.53-.95.94-1 2.53.2 3.74L17.5 22l3.6-3.55c1.2-1.21 1.14-2.8.19-3.74Z',
      key: '1t7hil',
    },
  ],
]);

export default CalendarHeart;
