import createLucideIcon from '../createLucideIcon';

/**
 * @component @name AlignVerticalDistributeCenter
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cmVjdCB3aWR0aD0iMTQiIGhlaWdodD0iNiIgeD0iNSIgeT0iMTQiIHJ4PSIyIiAvPgogIDxyZWN0IHdpZHRoPSIxMCIgaGVpZ2h0PSI2IiB4PSI3IiB5PSI0IiByeD0iMiIgLz4KICA8cGF0aCBkPSJNMjIgN2gtNSIgLz4KICA8cGF0aCBkPSJNNyA3SDEiIC8+CiAgPHBhdGggZD0iTTIyIDE3aC0zIiAvPgogIDxwYXRoIGQ9Ik01IDE3SDIiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/align-vertical-distribute-center
 * @see https://lucide.dev/guide/packages/lucide-react - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {JSX.Element} JSX Element
 *
 */
const AlignVerticalDistributeCenter = createLucideIcon('AlignVerticalDistributeCenter', [
  ['rect', { width: '14', height: '6', x: '5', y: '14', rx: '2', key: 'jmoj9s' }],
  ['rect', { width: '10', height: '6', x: '7', y: '4', rx: '2', key: 'aza5on' }],
  ['path', { d: 'M22 7h-5', key: 'o2endc' }],
  ['path', { d: 'M7 7H1', key: '105l6j' }],
  ['path', { d: 'M22 17h-3', key: '1lwga1' }],
  ['path', { d: 'M5 17H2', key: '1gx9xc' }],
]);

export default AlignVerticalDistributeCenter;
