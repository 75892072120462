import createLucideIcon from '../createLucideIcon';

/**
 * @component @name ClipboardEdit
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cmVjdCB3aWR0aD0iOCIgaGVpZ2h0PSI0IiB4PSI4IiB5PSIyIiByeD0iMSIgcnk9IjEiIC8+CiAgPHBhdGggZD0iTTEwLjQyIDEyLjYxYTIuMSAyLjEgMCAxIDEgMi45NyAyLjk3TDcuOTUgMjEgNCAyMmwuOTktMy45NSA1LjQzLTUuNDRaIiAvPgogIDxwYXRoIGQ9Ik0xNiA0aDJhMiAyIDAgMCAxIDIgMnYxNGEyIDIgMCAwIDEtMiAyaC01LjUiIC8+CiAgPHBhdGggZD0iTTQgMTMuNVY2YTIgMiAwIDAgMSAyLTJoMiIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/clipboard-edit
 * @see https://lucide.dev/guide/packages/lucide-react - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {JSX.Element} JSX Element
 *
 */
const ClipboardEdit = createLucideIcon('ClipboardEdit', [
  ['rect', { width: '8', height: '4', x: '8', y: '2', rx: '1', ry: '1', key: 'tgr4d6' }],
  [
    'path',
    { d: 'M10.42 12.61a2.1 2.1 0 1 1 2.97 2.97L7.95 21 4 22l.99-3.95 5.43-5.44Z', key: '1rgxu8' },
  ],
  ['path', { d: 'M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-5.5', key: 'cereej' }],
  ['path', { d: 'M4 13.5V6a2 2 0 0 1 2-2h2', key: '5ua5vh' }],
]);

export default ClipboardEdit;
