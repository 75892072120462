import createLucideIcon from '../createLucideIcon';

/**
 * @component @name Keyboard
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cmVjdCB3aWR0aD0iMjAiIGhlaWdodD0iMTYiIHg9IjIiIHk9IjQiIHJ4PSIyIiByeT0iMiIgLz4KICA8cGF0aCBkPSJNNiA4aC4wMDEiIC8+CiAgPHBhdGggZD0iTTEwIDhoLjAwMSIgLz4KICA8cGF0aCBkPSJNMTQgOGguMDAxIiAvPgogIDxwYXRoIGQ9Ik0xOCA4aC4wMDEiIC8+CiAgPHBhdGggZD0iTTggMTJoLjAwMSIgLz4KICA8cGF0aCBkPSJNMTIgMTJoLjAwMSIgLz4KICA8cGF0aCBkPSJNMTYgMTJoLjAwMSIgLz4KICA8cGF0aCBkPSJNNyAxNmgxMCIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/keyboard
 * @see https://lucide.dev/guide/packages/lucide-react - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {JSX.Element} JSX Element
 *
 */
const Keyboard = createLucideIcon('Keyboard', [
  ['rect', { width: '20', height: '16', x: '2', y: '4', rx: '2', ry: '2', key: '15u882' }],
  ['path', { d: 'M6 8h.001', key: '1ej0i3' }],
  ['path', { d: 'M10 8h.001', key: '1x2st2' }],
  ['path', { d: 'M14 8h.001', key: '1vkmyp' }],
  ['path', { d: 'M18 8h.001', key: 'kfsenl' }],
  ['path', { d: 'M8 12h.001', key: '1sjpby' }],
  ['path', { d: 'M12 12h.001', key: 'al75ts' }],
  ['path', { d: 'M16 12h.001', key: '931bgk' }],
  ['path', { d: 'M7 16h10', key: 'wp8him' }],
]);

export default Keyboard;
