import createLucideIcon from '../createLucideIcon';

/**
 * @component @name Wifi
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNNSAxM2ExMCAxMCAwIDAgMSAxNCAwIiAvPgogIDxwYXRoIGQ9Ik04LjUgMTYuNWE1IDUgMCAwIDEgNyAwIiAvPgogIDxwYXRoIGQ9Ik0yIDguODJhMTUgMTUgMCAwIDEgMjAgMCIgLz4KICA8bGluZSB4MT0iMTIiIHgyPSIxMi4wMSIgeTE9IjIwIiB5Mj0iMjAiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/wifi
 * @see https://lucide.dev/guide/packages/lucide-react - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {JSX.Element} JSX Element
 *
 */
const Wifi = createLucideIcon('Wifi', [
  ['path', { d: 'M5 13a10 10 0 0 1 14 0', key: '6v8j51' }],
  ['path', { d: 'M8.5 16.5a5 5 0 0 1 7 0', key: 'sej527' }],
  ['path', { d: 'M2 8.82a15 15 0 0 1 20 0', key: 'dnpr2z' }],
  ['line', { x1: '12', x2: '12.01', y1: '20', y2: '20', key: 'of4bc4' }],
]);

export default Wifi;
