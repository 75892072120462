import createLucideIcon from '../createLucideIcon';

/**
 * @component @name FileX2
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNNCAyMmgxNGEyIDIgMCAwIDAgMi0yVjcuNUwxNC41IDJINmEyIDIgMCAwIDAtMiAydjQiIC8+CiAgPHBhdGggZD0iTTE0IDJ2Nmg2IiAvPgogIDxwYXRoIGQ9Im0zIDEyLjUgNSA1IiAvPgogIDxwYXRoIGQ9Im04IDEyLjUtNSA1IiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/file-x-2
 * @see https://lucide.dev/guide/packages/lucide-react - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {JSX.Element} JSX Element
 *
 */
const FileX2 = createLucideIcon('FileX2', [
  ['path', { d: 'M4 22h14a2 2 0 0 0 2-2V7.5L14.5 2H6a2 2 0 0 0-2 2v4', key: '702lig' }],
  ['path', { d: 'M14 2v6h6', key: '1kof46' }],
  ['path', { d: 'm3 12.5 5 5', key: '1qls4r' }],
  ['path', { d: 'm8 12.5-5 5', key: 'b853mi' }],
]);

export default FileX2;
