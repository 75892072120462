import createLucideIcon from '../createLucideIcon';

/**
 * @component @name ScatterChart
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8Y2lyY2xlIGN4PSI3LjUiIGN5PSI3LjUiIHI9Ii41IiAvPgogIDxjaXJjbGUgY3g9IjE4LjUiIGN5PSI1LjUiIHI9Ii41IiAvPgogIDxjaXJjbGUgY3g9IjExLjUiIGN5PSIxMS41IiByPSIuNSIgLz4KICA8Y2lyY2xlIGN4PSI3LjUiIGN5PSIxNi41IiByPSIuNSIgLz4KICA8Y2lyY2xlIGN4PSIxNy41IiBjeT0iMTQuNSIgcj0iLjUiIC8+CiAgPHBhdGggZD0iTTMgM3YxOGgxOCIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/scatter-chart
 * @see https://lucide.dev/guide/packages/lucide-react - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {JSX.Element} JSX Element
 *
 */
const ScatterChart = createLucideIcon('ScatterChart', [
  ['circle', { cx: '7.5', cy: '7.5', r: '.5', key: '1x97lo' }],
  ['circle', { cx: '18.5', cy: '5.5', r: '.5', key: '56iowl' }],
  ['circle', { cx: '11.5', cy: '11.5', r: '.5', key: 'm9xkw9' }],
  ['circle', { cx: '7.5', cy: '16.5', r: '.5', key: '14ln9z' }],
  ['circle', { cx: '17.5', cy: '14.5', r: '.5', key: '14qxqt' }],
  ['path', { d: 'M3 3v18h18', key: '1s2lah' }],
]);

export default ScatterChart;
