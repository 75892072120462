import createLucideIcon from '../createLucideIcon';

/**
 * @component @name FolderSync
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNOSAyMEg0YTIgMiAwIDAgMS0yLTJWNWEyIDIgMCAwIDEgMi0yaDMuOWEyIDIgMCAwIDEgMS42OS45bC44MSAxLjJhMiAyIDAgMCAwIDEuNjcuOUgyMGEyIDIgMCAwIDEgMiAydjEiIC8+CiAgPHBhdGggZD0iTTEyIDEwdjRoNCIgLz4KICA8cGF0aCBkPSJtMTIgMTQgMS41LTEuNWMuOS0uOSAyLjItMS41IDMuNS0xLjVzMi42LjYgMy41IDEuNWMuNC40LjggMSAxIDEuNSIgLz4KICA8cGF0aCBkPSJNMjIgMjJ2LTRoLTQiIC8+CiAgPHBhdGggZD0ibTIyIDE4LTEuNSAxLjVjLS45LjktMi4xIDEuNS0zLjUgMS41cy0yLjYtLjYtMy41LTEuNWMtLjQtLjQtLjgtMS0xLTEuNSIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/folder-sync
 * @see https://lucide.dev/guide/packages/lucide-react - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {JSX.Element} JSX Element
 *
 */
const FolderSync = createLucideIcon('FolderSync', [
  [
    'path',
    {
      d: 'M9 20H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h3.9a2 2 0 0 1 1.69.9l.81 1.2a2 2 0 0 0 1.67.9H20a2 2 0 0 1 2 2v1',
      key: '1rkwto',
    },
  ],
  ['path', { d: 'M12 10v4h4', key: '1czhmt' }],
  [
    'path',
    { d: 'm12 14 1.5-1.5c.9-.9 2.2-1.5 3.5-1.5s2.6.6 3.5 1.5c.4.4.8 1 1 1.5', key: '25wejs' },
  ],
  ['path', { d: 'M22 22v-4h-4', key: '1ewp4q' }],
  [
    'path',
    { d: 'm22 18-1.5 1.5c-.9.9-2.1 1.5-3.5 1.5s-2.6-.6-3.5-1.5c-.4-.4-.8-1-1-1.5', key: 'vlp1j8' },
  ],
]);

export default FolderSync;
