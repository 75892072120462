import createLucideIcon from '../createLucideIcon';

/**
 * @component @name CalendarClock
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMjEgNy41VjZhMiAyIDAgMCAwLTItMkg1YTIgMiAwIDAgMC0yIDJ2MTRhMiAyIDAgMCAwIDIgMmgzLjUiIC8+CiAgPHBhdGggZD0iTTE2IDJ2NCIgLz4KICA8cGF0aCBkPSJNOCAydjQiIC8+CiAgPHBhdGggZD0iTTMgMTBoNSIgLz4KICA8cGF0aCBkPSJNMTcuNSAxNy41IDE2IDE2LjI1VjE0IiAvPgogIDxwYXRoIGQ9Ik0yMiAxNmE2IDYgMCAxIDEtMTIgMCA2IDYgMCAwIDEgMTIgMFoiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/calendar-clock
 * @see https://lucide.dev/guide/packages/lucide-react - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {JSX.Element} JSX Element
 *
 */
const CalendarClock = createLucideIcon('CalendarClock', [
  ['path', { d: 'M21 7.5V6a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h3.5', key: '1osxxc' }],
  ['path', { d: 'M16 2v4', key: '4m81vk' }],
  ['path', { d: 'M8 2v4', key: '1cmpym' }],
  ['path', { d: 'M3 10h5', key: 'r794hk' }],
  ['path', { d: 'M17.5 17.5 16 16.25V14', key: 're2vv1' }],
  ['path', { d: 'M22 16a6 6 0 1 1-12 0 6 6 0 0 1 12 0Z', key: 'ame013' }],
]);

export default CalendarClock;
