import createLucideIcon from '../createLucideIcon';

/**
 * @component @name Scaling
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMjEgMyA5IDE1IiAvPgogIDxwYXRoIGQ9Ik0xMiAzSDN2MThoMTh2LTkiIC8+CiAgPHBhdGggZD0iTTE2IDNoNXY1IiAvPgogIDxwYXRoIGQ9Ik0xNCAxNUg5di01IiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/scaling
 * @see https://lucide.dev/guide/packages/lucide-react - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {JSX.Element} JSX Element
 *
 */
const Scaling = createLucideIcon('Scaling', [
  ['path', { d: 'M21 3 9 15', key: '15kdhq' }],
  ['path', { d: 'M12 3H3v18h18v-9', key: '8suug0' }],
  ['path', { d: 'M16 3h5v5', key: '1806ms' }],
  ['path', { d: 'M14 15H9v-5', key: 'pi4jk9' }],
]);

export default Scaling;
