import createLucideIcon from '../createLucideIcon';

/**
 * @component @name FileDigit
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cmVjdCB3aWR0aD0iNCIgaGVpZ2h0PSI2IiB4PSIyIiB5PSIxMiIgcng9IjIiIC8+CiAgPHBhdGggZD0iTTE0IDJ2Nmg2IiAvPgogIDxwYXRoIGQ9Ik00IDIyaDE0YTIgMiAwIDAgMCAyLTJWNy41TDE0LjUgMkg2YTIgMiAwIDAgMC0yIDJ2NCIgLz4KICA8cGF0aCBkPSJNMTAgMTJoMnY2IiAvPgogIDxwYXRoIGQ9Ik0xMCAxOGg0IiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/file-digit
 * @see https://lucide.dev/guide/packages/lucide-react - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {JSX.Element} JSX Element
 *
 */
const FileDigit = createLucideIcon('FileDigit', [
  ['rect', { width: '4', height: '6', x: '2', y: '12', rx: '2', key: 'jm304g' }],
  ['path', { d: 'M14 2v6h6', key: '1kof46' }],
  ['path', { d: 'M4 22h14a2 2 0 0 0 2-2V7.5L14.5 2H6a2 2 0 0 0-2 2v4', key: '702lig' }],
  ['path', { d: 'M10 12h2v6', key: '12zw74' }],
  ['path', { d: 'M10 18h4', key: '1ulq68' }],
]);

export default FileDigit;
