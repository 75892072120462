import createLucideIcon from '../createLucideIcon';

/**
 * @component @name Podcast
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8Y2lyY2xlIGN4PSIxMiIgY3k9IjExIiByPSIxIiAvPgogIDxwYXRoIGQ9Ik0xMSAxN2ExIDEgMCAwIDEgMiAwYzAgLjUtLjM0IDMtLjUgNC41YS41LjUgMCAwIDEtMSAwYy0uMTYtMS41LS41LTQtLjUtNC41WiIgLz4KICA8cGF0aCBkPSJNOCAxNGE1IDUgMCAxIDEgOCAwIiAvPgogIDxwYXRoIGQ9Ik0xNyAxOC41YTkgOSAwIDEgMC0xMCAwIiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/podcast
 * @see https://lucide.dev/guide/packages/lucide-react - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {JSX.Element} JSX Element
 *
 */
const Podcast = createLucideIcon('Podcast', [
  ['circle', { cx: '12', cy: '11', r: '1', key: '1gvufo' }],
  [
    'path',
    {
      d: 'M11 17a1 1 0 0 1 2 0c0 .5-.34 3-.5 4.5a.5.5 0 0 1-1 0c-.16-1.5-.5-4-.5-4.5Z',
      key: '1n5fvv',
    },
  ],
  ['path', { d: 'M8 14a5 5 0 1 1 8 0', key: 'fc81rn' }],
  ['path', { d: 'M17 18.5a9 9 0 1 0-10 0', key: 'jqtxkf' }],
]);

export default Podcast;
