import createLucideIcon from '../createLucideIcon';

/**
 * @component @name FileCog
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8Y2lyY2xlIGN4PSI2IiBjeT0iMTMiIHI9IjMiIC8+CiAgPHBhdGggZD0ibTkuNyAxNC40LS45LS4zIiAvPgogIDxwYXRoIGQ9Im0zLjIgMTEuOS0uOS0uMyIgLz4KICA8cGF0aCBkPSJtNC42IDE2LjcuMy0uOSIgLz4KICA8cGF0aCBkPSJtNy42IDE2LjctLjQtMSIgLz4KICA8cGF0aCBkPSJtNC44IDEwLjMtLjQtMSIgLz4KICA8cGF0aCBkPSJtMi4zIDE0LjYgMS0uNCIgLz4KICA8cGF0aCBkPSJtOC43IDExLjggMS0uNCIgLz4KICA8cGF0aCBkPSJtNy40IDkuMy0uMy45IiAvPgogIDxwYXRoIGQ9Ik0xNCAydjZoNiIgLz4KICA8cGF0aCBkPSJNNCA1LjVWNGEyIDIgMCAwIDEgMi0yaDguNUwyMCA3LjVWMjBhMiAyIDAgMCAxLTIgMkg2YTIgMiAwIDAgMS0yLTEuNSIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/file-cog
 * @see https://lucide.dev/guide/packages/lucide-react - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {JSX.Element} JSX Element
 *
 */
const FileCog = createLucideIcon('FileCog', [
  ['circle', { cx: '6', cy: '13', r: '3', key: '1z65bp' }],
  ['path', { d: 'm9.7 14.4-.9-.3', key: 'o1luaq' }],
  ['path', { d: 'm3.2 11.9-.9-.3', key: 'qm3zk5' }],
  ['path', { d: 'm4.6 16.7.3-.9', key: '1o0ect' }],
  ['path', { d: 'm7.6 16.7-.4-1', key: '1ym8d1' }],
  ['path', { d: 'm4.8 10.3-.4-1', key: '18q26g' }],
  ['path', { d: 'm2.3 14.6 1-.4', key: '121m88' }],
  ['path', { d: 'm8.7 11.8 1-.4', key: '9meqp2' }],
  ['path', { d: 'm7.4 9.3-.3.9', key: '136qqn' }],
  ['path', { d: 'M14 2v6h6', key: '1kof46' }],
  [
    'path',
    { d: 'M4 5.5V4a2 2 0 0 1 2-2h8.5L20 7.5V20a2 2 0 0 1-2 2H6a2 2 0 0 1-2-1.5', key: 'xwe04' },
  ],
]);

export default FileCog;
