import createLucideIcon from '../createLucideIcon';

/**
 * @component @name FileVolume
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNNCAyMmgxNGEyIDIgMCAwIDAgMi0yVjcuNUwxNC41IDJINmEyIDIgMCAwIDAtMiAydjMiIC8+CiAgPHBvbHlsaW5lIHBvaW50cz0iMTQgMiAxNCA4IDIwIDgiIC8+CiAgPHBhdGggZD0ibTcgMTAtMyAySDJ2NGgybDMgMnYtOFoiIC8+CiAgPHBhdGggZD0iTTExIDExYy42NC44IDEgMS44NyAxIDNzLS4zNiAyLjItMSAzIiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/file-volume
 * @see https://lucide.dev/guide/packages/lucide-react - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {JSX.Element} JSX Element
 *
 */
const FileVolume = createLucideIcon('FileVolume', [
  ['path', { d: 'M4 22h14a2 2 0 0 0 2-2V7.5L14.5 2H6a2 2 0 0 0-2 2v3', key: 'am10z3' }],
  ['polyline', { points: '14 2 14 8 20 8', key: '1ew0cm' }],
  ['path', { d: 'm7 10-3 2H2v4h2l3 2v-8Z', key: 'tazg57' }],
  ['path', { d: 'M11 11c.64.8 1 1.87 1 3s-.36 2.2-1 3', key: '1yej3m' }],
]);

export default FileVolume;
