import createLucideIcon from '../createLucideIcon';

/**
 * @component @name FileStack
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTYgMnY1aDUiIC8+CiAgPHBhdGggZD0iTTIxIDZ2Ni41YzAgLjgtLjcgMS41LTEuNSAxLjVoLTdjLS44IDAtMS41LS43LTEuNS0xLjV2LTljMC0uOC43LTEuNSAxLjUtMS41SDE3bDQgNHoiIC8+CiAgPHBhdGggZD0iTTcgOHY4LjhjMCAuMy4yLjYuNC44LjIuMi41LjQuOC40SDE1IiAvPgogIDxwYXRoIGQ9Ik0zIDEydjguOGMwIC4zLjIuNi40LjguMi4yLjUuNC44LjRIMTEiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/file-stack
 * @see https://lucide.dev/guide/packages/lucide-react - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {JSX.Element} JSX Element
 *
 */
const FileStack = createLucideIcon('FileStack', [
  ['path', { d: 'M16 2v5h5', key: 'kt2in0' }],
  [
    'path',
    {
      d: 'M21 6v6.5c0 .8-.7 1.5-1.5 1.5h-7c-.8 0-1.5-.7-1.5-1.5v-9c0-.8.7-1.5 1.5-1.5H17l4 4z',
      key: '1km23n',
    },
  ],
  ['path', { d: 'M7 8v8.8c0 .3.2.6.4.8.2.2.5.4.8.4H15', key: '16874u' }],
  ['path', { d: 'M3 12v8.8c0 .3.2.6.4.8.2.2.5.4.8.4H11', key: 'k2ox98' }],
]);

export default FileStack;
