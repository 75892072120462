import createLucideIcon from '../createLucideIcon';

/**
 * @component @name FolderSymlink
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMiA5VjVjMC0xLjEuOS0yIDItMmgzLjkzYTIgMiAwIDAgMSAxLjY2LjlsLjgyIDEuMmEyIDIgMCAwIDAgMS42Ni45SDIwYTIgMiAwIDAgMSAyIDJ2MTBhMiAyIDAgMCAxLTIgMkgyIiAvPgogIDxwYXRoIGQ9Im04IDE2IDMtMy0zLTMiIC8+CiAgPHBhdGggZD0iTTIgMTZ2LTFhMiAyIDAgMCAxIDItMmg2IiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/folder-symlink
 * @see https://lucide.dev/guide/packages/lucide-react - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {JSX.Element} JSX Element
 *
 */
const FolderSymlink = createLucideIcon('FolderSymlink', [
  [
    'path',
    {
      d: 'M2 9V5c0-1.1.9-2 2-2h3.93a2 2 0 0 1 1.66.9l.82 1.2a2 2 0 0 0 1.66.9H20a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H2',
      key: '1or2t8',
    },
  ],
  ['path', { d: 'm8 16 3-3-3-3', key: 'rlqrt1' }],
  ['path', { d: 'M2 16v-1a2 2 0 0 1 2-2h6', key: 'pgw8ln' }],
]);

export default FolderSymlink;
