import createLucideIcon from '../createLucideIcon';

/**
 * @component @name Tags
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNOSA1SDJ2N2w2LjI5IDYuMjljLjk0Ljk0IDIuNDguOTQgMy40MiAwbDMuNTgtMy41OGMuOTQtLjk0Ljk0LTIuNDggMC0zLjQyTDkgNVoiIC8+CiAgPHBhdGggZD0iTTYgOS4wMVY5IiAvPgogIDxwYXRoIGQ9Im0xNSA1IDYuMyA2LjNhMi40IDIuNCAwIDAgMSAwIDMuNEwxNyAxOSIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/tags
 * @see https://lucide.dev/guide/packages/lucide-react - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {JSX.Element} JSX Element
 *
 */
const Tags = createLucideIcon('Tags', [
  [
    'path',
    {
      d: 'M9 5H2v7l6.29 6.29c.94.94 2.48.94 3.42 0l3.58-3.58c.94-.94.94-2.48 0-3.42L9 5Z',
      key: 'gt587u',
    },
  ],
  ['path', { d: 'M6 9.01V9', key: '1flxpt' }],
  ['path', { d: 'm15 5 6.3 6.3a2.4 2.4 0 0 1 0 3.4L17 19', key: '1cbfv1' }],
]);

export default Tags;
