import createLucideIcon from '../createLucideIcon';

/**
 * @component @name ShieldOff
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTkuNyAxNGE2LjkgNi45IDAgMCAwIC4zLTJWNWwtOC0zLTMuMiAxLjIiIC8+CiAgPHBhdGggZD0ibTIgMiAyMCAyMCIgLz4KICA8cGF0aCBkPSJNNC43IDQuNyA0IDV2N2MwIDYgOCAxMCA4IDEwYTIwLjMgMjAuMyAwIDAgMCA1LjYyLTQuMzgiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/shield-off
 * @see https://lucide.dev/guide/packages/lucide-react - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {JSX.Element} JSX Element
 *
 */
const ShieldOff = createLucideIcon('ShieldOff', [
  ['path', { d: 'M19.7 14a6.9 6.9 0 0 0 .3-2V5l-8-3-3.2 1.2', key: '342pvf' }],
  ['path', { d: 'm2 2 20 20', key: '1ooewy' }],
  ['path', { d: 'M4.7 4.7 4 5v7c0 6 8 10 8 10a20.3 20.3 0 0 0 5.62-4.38', key: 'p0ycf4' }],
]);

export default ShieldOff;
