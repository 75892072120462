import createLucideIcon from '../createLucideIcon';

/**
 * @component @name ShieldEllipsis
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTIgMjJzOC00IDgtMTBWNWwtOC0zLTggM3Y3YzAgNiA4IDEwIDggMTAiIC8+CiAgPHBhdGggZD0iTTggMTFoLjAxIiAvPgogIDxwYXRoIGQ9Ik0xMiAxMWguMDEiIC8+CiAgPHBhdGggZD0iTTE2IDExaC4wMSIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/shield-ellipsis
 * @see https://lucide.dev/guide/packages/lucide-react - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {JSX.Element} JSX Element
 *
 */
const ShieldEllipsis = createLucideIcon('ShieldEllipsis', [
  ['path', { d: 'M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10', key: '1irkt0' }],
  ['path', { d: 'M8 11h.01', key: '1dfujw' }],
  ['path', { d: 'M12 11h.01', key: 'z322tv' }],
  ['path', { d: 'M16 11h.01', key: 'xkw8gn' }],
]);

export default ShieldEllipsis;
