import createLucideIcon from '../createLucideIcon';

/**
 * @component @name CalendarOff
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNNC4xOCA0LjE4QTIgMiAwIDAgMCAzIDZ2MTRhMiAyIDAgMCAwIDIgMmgxNGEyIDIgMCAwIDAgMS44Mi0xLjE4IiAvPgogIDxwYXRoIGQ9Ik0yMSAxNS41VjZhMiAyIDAgMCAwLTItMkg5LjUiIC8+CiAgPHBhdGggZD0iTTE2IDJ2NCIgLz4KICA8cGF0aCBkPSJNMyAxMGg3IiAvPgogIDxwYXRoIGQ9Ik0yMSAxMGgtNS41IiAvPgogIDxsaW5lIHgxPSIyIiB4Mj0iMjIiIHkxPSIyIiB5Mj0iMjIiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/calendar-off
 * @see https://lucide.dev/guide/packages/lucide-react - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {JSX.Element} JSX Element
 *
 */
const CalendarOff = createLucideIcon('CalendarOff', [
  [
    'path',
    { d: 'M4.18 4.18A2 2 0 0 0 3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 1.82-1.18', key: '1feomx' },
  ],
  ['path', { d: 'M21 15.5V6a2 2 0 0 0-2-2H9.5', key: 'yhw86o' }],
  ['path', { d: 'M16 2v4', key: '4m81vk' }],
  ['path', { d: 'M3 10h7', key: '1wap6i' }],
  ['path', { d: 'M21 10h-5.5', key: 'quycpq' }],
  ['line', { x1: '2', x2: '22', y1: '2', y2: '22', key: 'a6p6uj' }],
]);

export default CalendarOff;
