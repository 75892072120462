import createLucideIcon from '../createLucideIcon';

/**
 * @component @name FileBox
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTQuNSAyMkgxOGEyIDIgMCAwIDAgMi0yVjcuNUwxNC41IDJINmEyIDIgMCAwIDAtMiAydjQiIC8+CiAgPHBvbHlsaW5lIHBvaW50cz0iMTQgMiAxNCA4IDIwIDgiIC8+CiAgPHBhdGggZD0iTTIuOTcgMTMuMTJjLS42LjM2LS45NyAxLjAyLS45NyAxLjc0djMuMjhjMCAuNzIuMzcgMS4zOC45NyAxLjc0bDMgMS44M2MuNjMuMzkgMS40My4zOSAyLjA2IDBsMy0xLjgzYy42LS4zNi45Ny0xLjAyLjk3LTEuNzR2LTMuMjhjMC0uNzItLjM3LTEuMzgtLjk3LTEuNzRsLTMtMS44M2ExLjk3IDEuOTcgMCAwIDAtMi4wNiAwbC0zIDEuODNaIiAvPgogIDxwYXRoIGQ9Im03IDE3LTQuNzQtMi44NSIgLz4KICA8cGF0aCBkPSJtNyAxNyA0Ljc0LTIuODUiIC8+CiAgPHBhdGggZD0iTTcgMTd2NSIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/file-box
 * @see https://lucide.dev/guide/packages/lucide-react - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {JSX.Element} JSX Element
 *
 */
const FileBox = createLucideIcon('FileBox', [
  ['path', { d: 'M14.5 22H18a2 2 0 0 0 2-2V7.5L14.5 2H6a2 2 0 0 0-2 2v4', key: 'h7jej2' }],
  ['polyline', { points: '14 2 14 8 20 8', key: '1ew0cm' }],
  [
    'path',
    {
      d: 'M2.97 13.12c-.6.36-.97 1.02-.97 1.74v3.28c0 .72.37 1.38.97 1.74l3 1.83c.63.39 1.43.39 2.06 0l3-1.83c.6-.36.97-1.02.97-1.74v-3.28c0-.72-.37-1.38-.97-1.74l-3-1.83a1.97 1.97 0 0 0-2.06 0l-3 1.83Z',
      key: 'f4a3oc',
    },
  ],
  ['path', { d: 'm7 17-4.74-2.85', key: 'etm6su' }],
  ['path', { d: 'm7 17 4.74-2.85', key: '5xuooz' }],
  ['path', { d: 'M7 17v5', key: '1yj1jh' }],
]);

export default FileBox;
