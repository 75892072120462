import createLucideIcon from '../createLucideIcon';

/**
 * @component @name Hop
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTcuNSA1LjVDMTkgNyAyMC41IDkgMjEgMTFjLTIuNS41LTUgLjUtOC41LTEiIC8+CiAgPHBhdGggZD0iTTUuNSAxNy41QzcgMTkgOSAyMC41IDExIDIxYy41LTIuNS41LTUtMS04LjUiIC8+CiAgPHBhdGggZD0iTTE2LjUgMTEuNWMxIDIgMSAzLjUgMSA2LTIuNSAwLTQgMC02LTEiIC8+CiAgPHBhdGggZD0iTTIwIDExLjVjMSAxLjUgMiAzLjUgMiA0LjUtMS41LjUtMyAwLTQuNS0uNSIgLz4KICA8cGF0aCBkPSJNMTEuNSAyMGMxLjUgMSAzLjUgMiA0LjUgMiAuNS0xLjUgMC0zLS41LTQuNSIgLz4KICA8cGF0aCBkPSJNMjAuNSAxNi41YzEgMiAxLjUgMy41IDEuNSA1LjUtMiAwLTMuNS0uNS01LjUtMS41IiAvPgogIDxwYXRoIGQ9Ik00Ljc4MyA0Ljc4MkM4LjQ5MyAxLjA3MiAxNC41IDEgMTggNWMtMSAxLTQuNSAyLTYuNSAxLjUgMSAxLjUgMSA0IC41IDUuNS0xLjUuNS00IC41LTUuNS0uNUM3IDEzLjUgNiAxNyA1IDE4Yy00LTMuNS0zLjkyNy05LjUwOC0uMjE3LTEzLjIxOFoiIC8+CiAgPHBhdGggZD0iTTQuNSA0LjUgMyAzYy0uMTg0LS4xODUtLjE4NC0uODE2IDAtMSIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/hop
 * @see https://lucide.dev/guide/packages/lucide-react - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {JSX.Element} JSX Element
 *
 */
const Hop = createLucideIcon('Hop', [
  ['path', { d: 'M17.5 5.5C19 7 20.5 9 21 11c-2.5.5-5 .5-8.5-1', key: 'l0z2za' }],
  ['path', { d: 'M5.5 17.5C7 19 9 20.5 11 21c.5-2.5.5-5-1-8.5', key: '1mqyjd' }],
  ['path', { d: 'M16.5 11.5c1 2 1 3.5 1 6-2.5 0-4 0-6-1', key: '10xoad' }],
  ['path', { d: 'M20 11.5c1 1.5 2 3.5 2 4.5-1.5.5-3 0-4.5-.5', key: '1a4gpx' }],
  ['path', { d: 'M11.5 20c1.5 1 3.5 2 4.5 2 .5-1.5 0-3-.5-4.5', key: '1ufrz1' }],
  ['path', { d: 'M20.5 16.5c1 2 1.5 3.5 1.5 5.5-2 0-3.5-.5-5.5-1.5', key: '1ok5d2' }],
  [
    'path',
    {
      d: 'M4.783 4.782C8.493 1.072 14.5 1 18 5c-1 1-4.5 2-6.5 1.5 1 1.5 1 4 .5 5.5-1.5.5-4 .5-5.5-.5C7 13.5 6 17 5 18c-4-3.5-3.927-9.508-.217-13.218Z',
      key: '8hlroy',
    },
  ],
  ['path', { d: 'M4.5 4.5 3 3c-.184-.185-.184-.816 0-1', key: 'q3aj97' }],
]);

export default Hop;
