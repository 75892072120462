import createLucideIcon from '../createLucideIcon';

/**
 * @component @name FileArchive
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNNCAyMlY0YzAtLjUuMi0xIC42LTEuNEM1IDIuMiA1LjUgMiA2IDJoOC41TDIwIDcuNVYyMGMwIC41LS4yIDEtLjYgMS40LS40LjQtLjkuNi0xLjQuNmgtMiIgLz4KICA8cG9seWxpbmUgcG9pbnRzPSIxNCAyIDE0IDggMjAgOCIgLz4KICA8Y2lyY2xlIGN4PSIxMCIgY3k9IjIwIiByPSIyIiAvPgogIDxwYXRoIGQ9Ik0xMCA3VjYiIC8+CiAgPHBhdGggZD0iTTEwIDEydi0xIiAvPgogIDxwYXRoIGQ9Ik0xMCAxOHYtMiIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/file-archive
 * @see https://lucide.dev/guide/packages/lucide-react - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {JSX.Element} JSX Element
 *
 */
const FileArchive = createLucideIcon('FileArchive', [
  [
    'path',
    {
      d: 'M4 22V4c0-.5.2-1 .6-1.4C5 2.2 5.5 2 6 2h8.5L20 7.5V20c0 .5-.2 1-.6 1.4-.4.4-.9.6-1.4.6h-2',
      key: '1u864v',
    },
  ],
  ['polyline', { points: '14 2 14 8 20 8', key: '1ew0cm' }],
  ['circle', { cx: '10', cy: '20', r: '2', key: '1xzdoj' }],
  ['path', { d: 'M10 7V6', key: 'dljcrl' }],
  ['path', { d: 'M10 12v-1', key: 'v7bkov' }],
  ['path', { d: 'M10 18v-2', key: '1cjy8d' }],
]);

export default FileArchive;
