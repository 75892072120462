import createLucideIcon from '../createLucideIcon';

/**
 * @component @name Key
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8Y2lyY2xlIGN4PSI3LjUiIGN5PSIxNS41IiByPSI1LjUiIC8+CiAgPHBhdGggZD0ibTIxIDItOS42IDkuNiIgLz4KICA8cGF0aCBkPSJtMTUuNSA3LjUgMyAzTDIyIDdsLTMtMyIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/key
 * @see https://lucide.dev/guide/packages/lucide-react - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {JSX.Element} JSX Element
 *
 */
const Key = createLucideIcon('Key', [
  ['circle', { cx: '7.5', cy: '15.5', r: '5.5', key: 'yqb3hr' }],
  ['path', { d: 'm21 2-9.6 9.6', key: '1j0ho8' }],
  ['path', { d: 'm15.5 7.5 3 3L22 7l-3-3', key: '1rn1fs' }],
]);

export default Key;
