import createLucideIcon from '../createLucideIcon';

/**
 * @component @name FileClock
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTYgMjJoMmMuNSAwIDEtLjIgMS40LS42LjQtLjQuNi0uOS42LTEuNFY3LjVMMTQuNSAySDZjLS41IDAtMSAuMi0xLjQuNkM0LjIgMyA0IDMuNSA0IDR2MyIgLz4KICA8cG9seWxpbmUgcG9pbnRzPSIxNCAyIDE0IDggMjAgOCIgLz4KICA8Y2lyY2xlIGN4PSI4IiBjeT0iMTYiIHI9IjYiIC8+CiAgPHBhdGggZD0iTTkuNSAxNy41IDggMTYuMjVWMTQiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/file-clock
 * @see https://lucide.dev/guide/packages/lucide-react - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {JSX.Element} JSX Element
 *
 */
const FileClock = createLucideIcon('FileClock', [
  [
    'path',
    {
      d: 'M16 22h2c.5 0 1-.2 1.4-.6.4-.4.6-.9.6-1.4V7.5L14.5 2H6c-.5 0-1 .2-1.4.6C4.2 3 4 3.5 4 4v3',
      key: '9lo3o3',
    },
  ],
  ['polyline', { points: '14 2 14 8 20 8', key: '1ew0cm' }],
  ['circle', { cx: '8', cy: '16', r: '6', key: '10v15b' }],
  ['path', { d: 'M9.5 17.5 8 16.25V14', key: '1o80t2' }],
]);

export default FileClock;
