import createLucideIcon from '../createLucideIcon';

/**
 * @component @name PenTool
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJtMTIgMTkgNy03IDMgMy03IDctMy0zeiIgLz4KICA8cGF0aCBkPSJtMTggMTMtMS41LTcuNUwyIDJsMy41IDE0LjVMMTMgMThsNS01eiIgLz4KICA8cGF0aCBkPSJtMiAyIDcuNTg2IDcuNTg2IiAvPgogIDxjaXJjbGUgY3g9IjExIiBjeT0iMTEiIHI9IjIiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/pen-tool
 * @see https://lucide.dev/guide/packages/lucide-react - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {JSX.Element} JSX Element
 *
 */
const PenTool = createLucideIcon('PenTool', [
  ['path', { d: 'm12 19 7-7 3 3-7 7-3-3z', key: 'rklqx2' }],
  ['path', { d: 'm18 13-1.5-7.5L2 2l3.5 14.5L13 18l5-5z', key: '1et58u' }],
  ['path', { d: 'm2 2 7.586 7.586', key: 'etlp93' }],
  ['circle', { cx: '11', cy: '11', r: '2', key: 'xmgehs' }],
]);

export default PenTool;
