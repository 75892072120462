import createLucideIcon from '../createLucideIcon';

/**
 * @component @name Flower
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTIgNy41YTQuNSA0LjUgMCAxIDEgNC41IDQuNU0xMiA3LjVBNC41IDQuNSAwIDEgMCA3LjUgMTJNMTIgNy41VjltLTQuNSAzYTQuNSA0LjUgMCAxIDAgNC41IDQuNU03LjUgMTJIOW03LjUgMGE0LjUgNC41IDAgMSAxLTQuNSA0LjVtNC41LTQuNUgxNW0tMyA0LjVWMTUiIC8+CiAgPGNpcmNsZSBjeD0iMTIiIGN5PSIxMiIgcj0iMyIgLz4KICA8cGF0aCBkPSJtOCAxNiAxLjUtMS41IiAvPgogIDxwYXRoIGQ9Ik0xNC41IDkuNSAxNiA4IiAvPgogIDxwYXRoIGQ9Im04IDggMS41IDEuNSIgLz4KICA8cGF0aCBkPSJNMTQuNSAxNC41IDE2IDE2IiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/flower
 * @see https://lucide.dev/guide/packages/lucide-react - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {JSX.Element} JSX Element
 *
 */
const Flower = createLucideIcon('Flower', [
  [
    'path',
    {
      d: 'M12 7.5a4.5 4.5 0 1 1 4.5 4.5M12 7.5A4.5 4.5 0 1 0 7.5 12M12 7.5V9m-4.5 3a4.5 4.5 0 1 0 4.5 4.5M7.5 12H9m7.5 0a4.5 4.5 0 1 1-4.5 4.5m4.5-4.5H15m-3 4.5V15',
      key: '51z86h',
    },
  ],
  ['circle', { cx: '12', cy: '12', r: '3', key: '1v7zrd' }],
  ['path', { d: 'm8 16 1.5-1.5', key: 'ce6zph' }],
  ['path', { d: 'M14.5 9.5 16 8', key: '1kzrzb' }],
  ['path', { d: 'm8 8 1.5 1.5', key: '1yv88w' }],
  ['path', { d: 'M14.5 14.5 16 16', key: '12xhjh' }],
]);

export default Flower;
