import createLucideIcon from '../createLucideIcon';

/**
 * @component @name School
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJtNCA2IDgtNCA4IDQiIC8+CiAgPHBhdGggZD0ibTE4IDEwIDQgMnY4YTIgMiAwIDAgMS0yIDJINGEyIDIgMCAwIDEtMi0ydi04bDQtMiIgLz4KICA8cGF0aCBkPSJNMTQgMjJ2LTRhMiAyIDAgMCAwLTItMnYwYTIgMiAwIDAgMC0yIDJ2NCIgLz4KICA8cGF0aCBkPSJNMTggNXYxNyIgLz4KICA8cGF0aCBkPSJNNiA1djE3IiAvPgogIDxjaXJjbGUgY3g9IjEyIiBjeT0iOSIgcj0iMiIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/school
 * @see https://lucide.dev/guide/packages/lucide-react - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {JSX.Element} JSX Element
 *
 */
const School = createLucideIcon('School', [
  ['path', { d: 'm4 6 8-4 8 4', key: '1q0ilc' }],
  ['path', { d: 'm18 10 4 2v8a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2v-8l4-2', key: '1vwozw' }],
  ['path', { d: 'M14 22v-4a2 2 0 0 0-2-2v0a2 2 0 0 0-2 2v4', key: 'cpkuc4' }],
  ['path', { d: 'M18 5v17', key: '1sw6gf' }],
  ['path', { d: 'M6 5v17', key: '1xfsm0' }],
  ['circle', { cx: '12', cy: '9', r: '2', key: '1092wv' }],
]);

export default School;
