import createLucideIcon from '../createLucideIcon';

/**
 * @component @name HelpingHand
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJtMyAxNSA1LjEyLTUuMTJBMyAzIDAgMCAxIDEwLjI0IDlIMTNhMiAyIDAgMSAxIDAgNGgtMi41bTQtLjY4IDQuMTctNC44OWExLjg4IDEuODggMCAwIDEgMi45MiAyLjM2bC00LjIgNS45NEEzIDMgMCAwIDEgMTQuOTYgMTdIOS44M2EyIDIgMCAwIDAtMS40Mi41OUw3IDE5IiAvPgogIDxwYXRoIGQ9Im0yIDE0IDYgNiIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/helping-hand
 * @see https://lucide.dev/guide/packages/lucide-react - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {JSX.Element} JSX Element
 *
 */
const HelpingHand = createLucideIcon('HelpingHand', [
  [
    'path',
    {
      d: 'm3 15 5.12-5.12A3 3 0 0 1 10.24 9H13a2 2 0 1 1 0 4h-2.5m4-.68 4.17-4.89a1.88 1.88 0 0 1 2.92 2.36l-4.2 5.94A3 3 0 0 1 14.96 17H9.83a2 2 0 0 0-1.42.59L7 19',
      key: 'nitrv7',
    },
  ],
  ['path', { d: 'm2 14 6 6', key: 'g6j1uo' }],
]);

export default HelpingHand;
